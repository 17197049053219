<template>
  <PageContainer class="has-tabs">
    <template slot="title">
      <!-- 标题栏 -->
      <pageTitle pageTitle="合规风险设置"></pageTitle>
    </template>
    <!-- 内容区 -->
    <template slot="content">
      <!-- tabs -->
      <ComplianceRisk></ComplianceRisk>
    </template>
  </PageContainer>
</template>

<script>
  import PageContainer from '@/components/PageContainer';
  import PageTitle from '@/components/PageTitle';
  import ComplianceRisk from '@/components/ComplianceRisk';
  export default {
    components: {
      PageContainer,
      PageTitle,
      ComplianceRisk
    },
    data() {
      return {
        activeTab: '1'
      };
    },
    created() {
      this.activeTab = this.$route.query.tab || '1';
    },
    methods: {
      handleTabClick() {}
    }
  };
</script>

<style></style>
